import React from "react"
import Heading from "../../shared/Heading"
import "./benefits.scss"
import { Col, Row } from "react-bootstrap"

function BenefitsSection() {
  const data = [
    {
      name: "Flexible Working Hours",
      img: "/careers/image-1.png",
    },
    {
      name: "Hybrid Work Culture",
      img: "/careers/image-2.png",
    },
    {
      name: "Training Budget",
      img: "/careers/image-3.png",
    },
    {
      name: "HealthCare",
      img: "/careers/image-4.png",
    },
    {
      name: "Daily Lunch",
      img: "/careers/image-5.png",
    },
    {
      name: "Team Parties",
      img: "/careers/image-6.png",
    },
  ]
  return (
    <div className="careers-benifits">
      <div className="wrapper container">
        <Row container>
          <Col
            item
            md={6}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="left px-md-1"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <Heading variant="40" className={"text-capitalize line-height"}>
                Benefits of Working at CodeFulcrum
              </Heading>

              <p className="text">
                At CodeFulcrum we believe that talented employees are only as
                good as how well they perform. So we offer a bunch of perks that
                help employees boost moral and be more productive. We take care
                of our employees with fondness and treat them as a part of CF
                family.
              </p>
            </div>
          </Col>
          <Col item md={6} sm={12}>
            <div className="right">
              <Row container className="gy-3 justify-content-end">
                {data.map((item, i) => {
                  return (
                    <Col
                      item
                      md={6}
                      sm={4}
                      xs={6}
                      className="text-center"
                      mt={2}
                      pl={{ xs: 0, sm: 5, md: 12 }}
                      key={i}
                    >
                      <div
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        className="benafit-box"
                      >
                        <img
                          src={item.img}
                          alt={`service-card-${i}`}
                          loading="lazy"
                        />
                        <p className="name">{item.name}</p>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BenefitsSection
