import React from "react"
import { Link } from "gatsby"
import positions from "../../shared/Data/positions"
import "./positionSection.scss"

const PositionCard = ({ position, description, id }) => {
  return (
    <Link to={`/career-details/${id}`} key={id} className="position-link">
      <div className="card-main-wrapper ">
        <div
          key={id}
          className="position-card-wrapper"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="pc-inner-wrapper">
            <h4> {position} </h4>
            <div className="pc-underline" />
            <p className="pb-2"> {description} </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

function PositionSection() {
  return (
    <div className="position-wrapper">
      <div className="container">
        <h3 data-aos="fade-down" data-aos-duration="1000">
          Open Positions
        </h3>
        <div className="row gx-3  gy-4 gy-sm-0 ">
          {positions.map((position, index) => (
            <div className="col-md-4 mb-3" key={index}>
              <PositionCard {...position} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PositionSection
