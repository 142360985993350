import React from "react"
import Heading from "../../shared/Heading"
import "./Hero.scss"

import Button from "../../shared/Button"

export default function Hero({
  text,
  header,
  className = "",
  onClickHandler = "",
}) {
  return (
    <div
      className="hero-bg"
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
    >
      <div className="container position-relative">
        <div className="row pb-4">
          <div className="col-lg-6 col-sm-12 col-xs-12  hero-text-column-blog">
            <Heading variant="58" className="header text-capitalize">
              {<h1>{header}</h1>}
            </Heading>
            <div className={`hero-text-blog ${className}`}>{text}</div>
            <div className="button text-capitalize">
              {onClickHandler ? (
                <Button
                  text="JOIN US"
                  variant="fill"
                  onClick={onClickHandler}
                  className="text-capitalize"
                />
              ) : (
                <Button
                  text="JOIN US"
                  variant="fill"
                  href="/contact"
                  className="text-capitalize"
                />
              )}
            </div>
          </div>
          <div className="col-lg-6  col-sm-12  col-xs-12 ">
            <div className="hero-img-blog position-relative">
              <img
                src="/career-hero.svg"
                alt="career-hero"
                className="img-fluid display-tablet-none"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
